import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { Product } from '../../../model/product.model';
import { Catagory } from '../../../model/catagory.model';
import { Subcatagory } from '../../../model/subcatagory.model';
import { Manufacture } from '../../../model/manufacture.model';
//  import { Branch } from '../../../model/branch.model';
import { Feature } from '../../../model/feature.model';
import { Unit } from '../../../model/unit.model';
import { ActivatedRoute } from '@angular/router';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { Branch } from 'src/app/model/branch.model';
@Component({
  selector: 'app-editproduct',
  templateUrl: './editproduct.component.html',
  styleUrls: ['./editproduct.component.css']
})

export class EditproductComponent implements OnInit {
  thenBlockBase: any;
  prodsel: any;

  prods: any;
  units: any;
  derunits: any;
  selectedData: any = {
    prd_id: null, prd_name: null, error: null, message: null, prd_sub_cat_id: null, prd_cat_id: null,
    prd_base_unit_id: null, prd_minstock_alert: null, prd_maxstock_alert: null, prd_unit_ids: null,
    prd_default_unit_id: null, features: null, unit: null, basic: null, prd_loyalty_rate: null, unit_rates: null,
    data: null, prd_supplier: null, prd_tax: null, prd_tax_cat_id: null
  };
  
  favorites =[];
  dataSource = [];
  result = '';
  catagories: any;
  subcatagories: any;
  features: any;
  prdfeats: any;
  manuf: any;
  baseunit = [];
  derunitids = [];
  featids = [];
  deruntsbybase = [];
  search = [];
  proddata = [];
  prodid: any;
  buid: number;
  prd_unit_ids = [];
  selDisable: boolean;
  feats: [];
  baseunits = [];
  pageLoadingImg: boolean;
  prd_stock_statusUnchecked: boolean
  prd_stock_statusChecked: boolean;
  prd_stock_status = true;
  setStock = 0;
  unitsforstock = [];
  resDup: boolean;
  taxCategories = [];
  mrp: any; cmp_stock_id: any; addStock = 0; setstock = true; branchselids = []; purchase_rate: any; unit_ean: any; userType: any; branches: any;
  isDeletable: any;
  prodLoader: boolean;
  base_url: string;
  prdImgUrl: string;
  prd_img: any;
  cmpny: any;
  imageError: any;
  prd_status = [
    { id: 0, name: 'Suspend' },
    { id: 1, name: 'Active' },
  ];
  def_unit = 0;
  prd_id: any;
  der_base_unit:any;
  der_unit_name: any;
  der_unit_base_qty: any;
  der_unit_code: any;
  der_unit_display: any;
  der_unit_remarks: any;
  result2 = "";
  der_unit_type: any = 0;
  unit_all: string[];
  prod_alias: any;

  addEanBars = [{
    brc_id: 0,
    brc_ean_barcode: '',
    brc_unit_id : 0,
  }];
  extEanBars = [{
    brc_id: 0,
    brc_ean_barcode: '',
    loadingImg: false
  }];
  tmpaddEanBars = [{
    brc_id: 0,
    brc_ean_barcode: '',
    brc_unit_id : 0,
    loadingImg: false
  }];
  extDerivedEanBars = [];
  drvdAddBarcodCurntUnitId = 0;
  drvdAddBarcodCurntIndx = 0;
  cur_lang: string;
  excstaxCategories: any;
  checkIsExciseEnble: boolean;
  prd_excs_tax_cat_id: any;
  prd_excs_tax_type: any;
  setstockloading: boolean;
  exclude_branches: any;
  backendError: any;
  branch_Stk_alrt: any;
  branch_prd_max_stock: any;
  branch_prd_min_stock: any;
  branch_prd_maxstock_alert: any;
  branch_prd_minstock_alert: any;
  stock_error: string;
  branch_stock: any;
  StockAlert_branches: any;
  branchsId: null;
  display_stock_alert=0;
  constructor(private apiService: ApiService,
    private coreService: CoreService,private ActiveRoute: ActivatedRoute,private translate1: TranslateService) { }

  ngOnInit() {
    this.translate1.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.apiService.getAllBranches().subscribe((branch: Branch[]) => {
      this.branches = branch['data'];
    });

    this.cmpny = this.coreService.getUserData('cmpny');

    this.apiService.getClientSettingBykey({ key: "excise_duty_enable" }).subscribe((res) => {
      if (res["data"]) {
        this.checkIsExciseEnble = res["data"]["cs_value"] ? true : false;
        
        let searchval=new FormData;
        this.apiService.getAllExcsTaxCategory(searchval).subscribe((res) => {
          this.excstaxCategories = res.data;
        });

      }
    });
    this.prodLoader = true;
    this.cur_lang = sessionStorage.getItem("baseLang");
    this.aliasChecked()
    this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
      this.aliasChecked()
    })
    this.apiService.getAllTaxCategory().subscribe((resp) => {
      this.taxCategories = resp.data;
    });
    this.userType = this.coreService.getUserData('user_type');
    this.derunits = '';
    this.manuf = '';
    this.prods = '';
    this.feats = [];

    this.apiService.readCat().subscribe((catagories: Catagory[]) => {
      this.catagories = catagories['cat'];
    });

    this.apiService.getAllFavorites(1,1000).subscribe((resp) => {

      console.log("ggggggggggg");
      console.log(resp.data);
      this.favorites = resp.data;
    });

    this.apiService.readFeat().subscribe((features: Feature[]) => {
      this.feats = features['data'];
    });

    this.apiService.readManfs().subscribe((datas: Manufacture[]) => {
      this.manuf = datas['data'];

    });

    this.apiService.readFeat().subscribe((features: Feature[]) => {
      this.features = features['data'];
    });

    this.coreService.getToken();
    this.prd_stock_statusChecked = true;
    this.base_url = this.apiService.PHP_API_SERVER;
    this.prd_id = this.ActiveRoute.snapshot.paramMap.get('prd_id');
    if (this.prd_id && !isNaN(this.prd_id)){
      this.prodLoader = false;
      this.selectProd('');
    let searchval = new FormData();
    searchval.append("Prod_Id",this.prd_id );
    searchval.append("prod_name",'' );
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.prodsel = res['data'][0];
      this.selectProdDetail(res['data'][0]);
    });
    
    
   
    
    }
    this.apiService.getAllBranches().subscribe((branch: Branch[]) => {
      this.branches = branch['data'];
    });

  }
  langChange(){
    this.translate1.get(['Common.Suspend','Common.Active',]).subscribe((res: string) => {    
     
      
      this.prd_status = [
        { id: 0, name: res['Common.Suspend'] },
        { id: 1, name: res['Common.Active'] }
      ];
     
    });

  }
  aliasChecked(){
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      this.prod_alias = 'alias_true';
      
    }else{
      this.prod_alias =null;

    }
  }




  addFeatureValue(featids) {

    if (featids && featids.feat_id) {
      var featv = $('#fvlue').val();
      this.prdfeats.push({ "feat_id": featids.feat_id, "feat_name": featids.feat_name, "feat_val": featv.toString() });
      // Remove duplicates
      this.prdfeats = this.coreService.removeDumplicateValue(this.prdfeats);
      $('#fvlue').val('');
      this.featids = [];
    }

  }

  deleteFeat(id) {

    var type = id,
      i;
    var dataval = [];
    dataval = this.prdfeats;
    for (i = dataval.length - 1; i >= 0; --i) {
      if (dataval[i].feat_id == type) {
        dataval.splice(i, 1);
      }
    }

  }


  updateProduct(form) {
    this.pageLoadingImg = true;

    document.getElementById('updateProd').setAttribute('disabled', 'true');

    let searchval = new FormData(form);
    searchval.append('prd_cat_id', this.selectedData.prd_cat_id);
    searchval.append('prd_sub_cat_id', this.selectedData.prd_sub_cat_id);
    searchval.append('prd_base_unit_id', this.selectedData.prd_base_unit_id);
    searchval.append('prd_tax_cat_id', this.selectedData.prd_tax_cat_id);
    searchval.append('prd_supplier', this.selectedData.prd_supplier);
    searchval.append('prd_flag', this.selectedData.prd_flag);
    searchval.set('prd_stock_status', ""+this.prd_stock_status);
    searchval.append('addBarcodes', JSON.stringify(this.addEanBars));
    searchval.append('favorite_id', this.selectedData.favorite_id);

    if(this.checkIsExciseEnble){
      searchval.append('prd_excs_tax_type', this.selectedData.prd_excs_tax_type);
      searchval.append('prd_excs_tax_cat_id', this.selectedData.prd_excs_tax_cat_id);

    }
    this.apiService.updateProd(searchval).subscribe((prod: Product) => {
      this.pageLoadingImg = false;


      if (prod.error != null) {
        this.result = prod.error;
      } else {
        this.ProdUnits(this.prodid);
        this.apiService.readDerbyId(this.selectedData.prd_base_unit_id).subscribe((res: Unit[]) => {
          this.derunits = res['data'];
        });
        this.coreService.openSnackBar(prod.message, 'Close');
        this.result = '';

      }
      document.getElementById('updateProd').removeAttribute('disabled');
    });
  }
  deleteProd(prd_id){
    var dlt_prd = confirm("Are you sure to delete this product?");
    if (dlt_prd) {
      this.pageLoadingImg = true;
      const form :any={};
      form.prd_id =  prd_id;
      this.apiService.deleteProduct(form).subscribe((prod: Product) => {
        this.pageLoadingImg = false;
        if (prod.error != null) {
          this.result = prod.error;
          this.coreService.openSnackBar(this.result , 'Close');
        } else {
          this.coreService.openSnackBar(prod.message, 'Close');
          this.result = '';
          $('#edit_wrapper').hide();
        }
      });

    }
  }
  deleteProduct(form) {
    this.pageLoadingImg = true;

    document.getElementById('deleteProd').setAttribute('disabled', 'true');
    this.apiService.deleteProduct(form.value).subscribe((prod: Product) => {
      this.pageLoadingImg = false;


      if (prod.error != null) {
        this.result = prod.error;
      } else {
        this.coreService.openSnackBar(prod.message, 'Close');
        this.result = '';

      }
      document.getElementById('deleteProd').removeAttribute('disabled');
    });

    $('#edit_wrapper').hide();
  }


  editProUnit(form) {
    if (form.value.prd_unit_ids) {
      form.value.prd_unit_ids.push(form.value.prd_base_unit_id);
    } else {
      form.value.prd_unit_ids = [];
      form.value.prd_unit_ids.push(form.value.prd_base_unit_id);
    }

    // remove duplicates
    var names = form.value.prd_unit_ids;
    var uniqueNames = [];
    $.each(names, function (i, el) {
      if ($.inArray(el, uniqueNames) === -1) uniqueNames.push(el);
    });

    form.value.prd_unit_ids = uniqueNames;

    this.apiService.updateProdUnit(form.value).subscribe((prod: Product) => {

      if (prod.error != null) {
        this.result = prod.error;
      }
      else {
        this.coreService.openSnackBar(prod.message, 'Close');
        this.result = '';

      }

    });

  }

  editProFeat(form) {
    form.value.features = this.prdfeats;

    $("#fvlue").val("");
    this.apiService.updateProdFeat(form.value).subscribe((prod: Product) => {

      if (prod.error != null) {
        this.result = prod.error;
      }
      else {
        this.coreService.openSnackBar(prod.message, 'Close');
        this.result = '';

      }
    });

  }



  // search product
  selectProd(name: string) {
    let searchval = new FormData();
    searchval.append("prod_name", name);
    searchval.append("flag[]", "0");
    searchval.append("flag[]", "1");
    if(this.prod_alias){
      searchval.append('alias_search', '1');
    }
    this.apiService.getProdByName(searchval).subscribe((res) => {
      this.search = res['data'];
    });
  }

  selectProdDetailBarcode(barcode: string) {
    if(!barcode){
      $("#br_err").text("Enter Barcode");
      return false;
    }else{
      $("#br_err").text("");
    }
    let searchval = new FormData();
    searchval.append("barcode", barcode);
    searchval.append("flag[]", "0");
    searchval.append("flag[]", "1");
    this.apiService.getProdByBarcode(searchval).subscribe((res) => {
      this.search = res['data'];
      if(!res['data']){
        $("#br_err").text("Barcode not found");
      }
      this.selectProdDetail(res['data']);
    });
  }

  // search click
  selectProdDetail(product: any) {
    // console.log(product);
    if (product) {
      this.productStkAlrtClose()
      this.prodLoader = false;
      
      this.selectedData = product;
      this.selectedData.prd_cat_id = Number(this.selectedData.prd_cat_id);
      this.selectedData.favorite_id = Number(this.selectedData.prd_fav_id);
      this.selectedData.prd_sub_cat_id = Number(this.selectedData.prd_sub_cat_id);
      this.selectedData.prd_base_unit_id = Number(this.selectedData.prd_base_unit_id);
      this.getbaseunits();

      this.der_base_unit = this.selectedData.prd_base_unit_id;
      this.selectedData.prd_supplier = Number(this.selectedData.prd_supplier)
      this.subCatById(this.selectedData.prd_cat_id);
      this.selectedData.prd_id = product.prd_id;
      this.prodid = product.prd_id;
    
      this.def_unit = product.prd_default_unit_id;
      this.prdImgUrl = this.base_url + '/' + this.selectedData.prd_img_url;
      if (product && product.prd_stock_status == 1) {
        this.prd_stock_statusChecked = true;
        this.prd_stock_status = true;

      } else {
        this.prd_stock_statusChecked = false;
        this.prd_stock_status = false;

      }
      if(this.userType =='ADMIN'){
      this.StockAlertbranches(this.prodid)
      }
      this.prodGetAll();
      this.getManufctres();
      this.StockExcludeBranches(this.prodid)
      this.stockSet(this.prodid);
      this.ProdUnits(this.prodid);
      this.apiService.readDerbyId(product.prd_base_unit_id).subscribe((res: Unit[]) => {
        this.derunits = res['data'];

      });



      this.search = null;
      $('#edit_wrapper').show();
      $('.search-elements').height(10);
    }else{
      $('#edit_wrapper').hide();
    }
  }


  // edit basic
  selectProdEditBasic() {
    $('#editprodbase').show();
    $('#editfeat').hide();
    $('#editunit').hide();
  }

  updateTaxPerc(taxCatId) {
    const selTax = this.taxCategories.filter((taxCat) => taxCat.taxcat_id === taxCatId);
    this.selectedData.prd_tax = selTax[0].taxcat_tax_per;
  }
  selectSubCats(cat_id) {
    this.selectedData.prd_sub_cat_id = [];

    this.subCatById(cat_id);

    const selRow = this.catagories.filter((categ) => categ.cat_id === cat_id);
    this.selectedData.prd_tax_cat_id = selRow[0].cat_tax_cat_id;
    this.updateTaxPerc(selRow[0].cat_tax_cat_id);
    this.selectedData.prd_sub_cat_id = null;
  }


  // edit unit
  selectProdEditUnit() {
    $('#editprodbase').hide();
    $('#editfeat').hide();
    $('#editunit').show();
  }

  // edit feature
  selectProdEditFeat() {
    $('#editprodbase').hide();
    $('#editunit').hide();
    $('#editfeat').show();
  }

  //  common
  subCatById(cat_id: number) {
    this.apiService.getAllSubCatbyId(cat_id).subscribe((res: Subcatagory[]) => {
      this.subcatagories = res['data'];

    });
  }
  getManufctres() {
    this.apiService.readManfs().subscribe((datas: Manufacture[]) => {
      this.manuf = datas['data'];

    });
  }

  getbaseunits() {
    this.apiService.readBaseUnits().subscribe((res: Unit[]) => {
      this.baseunits = res['data'];
    });
  }


  prodGetAll() {
    this.apiService.getProdById(this.selectedData.prd_id).subscribe((prods:any) => {
      this.baseunit = prods['baseunit'];
      this.extEanBars =  prods['addtionalBarcodes'];
      this.isDeletable = prods['is_delatable'];
      this.prdfeats = prods['features'];
      this.selectedData.prd_ean = prods.product[0].prd_ean;
      this.selectedData.prd_code = prods.product[0].prd_code;
    });

    this.apiService.readDerbyId(this.selectedData.prd_base_unit_id).subscribe((units: Unit[]) => {
      this.deruntsbybase = units['data'];
    });
  }

  // Drop down

  // selct der unit and add base unit in table
  selectDerUnits(baseunitids: any) {

    if (baseunitids) {
      // sel der unit    
      this.apiService.readDerbyId(baseunitids.unit_id).subscribe((res: Unit[]) => {
        this.derunits = res['data'];

      });
    }
  }


  // form add unit
  productUnitSubmit(formgroup: { value: any; }) {

    console.log(this.prd_unit_ids);
    
    this.prd_unit_ids.forEach((element,i) => {
     if(this.prd_unit_ids[i].unit_id==this.def_unit){
      this.prd_unit_ids[i].is_default='on';
     }
    });
    console.log('after');
    console.log(this.prd_unit_ids);
    
    formgroup.value.prd_id = this.prodid;

    formgroup.value.prd_units = this.prd_unit_ids;
    // addBarcodes
    this.apiService.editProductUnit(formgroup.value).subscribe((res: Product) => {
      if (res.error != null) {
        $("#err").text(res.error);
      }
      else {
        this.coreService.createfunct(formgroup, res.error, 'Updated');
        res.error = '';
        $("#err").text("");
        $('#dunit_valid').hide();
        $("#set-derivedModal").hide();
        $('#closeet-derivedModal').click();

      }

    });

  }

  //form add Features
  productFeatSubmit(formgroup: { value: any; }) {
    formgroup.value.fetprod_prod_id = this.prodid;
    formgroup.value.features = this.prdfeats;

    this.apiService.updateProdFeat(formgroup.value).subscribe((res: Product) => {
      if (res.error != null) {
      }
      else {
        this.coreService.createfunct(formgroup, res.error, 'Updated');
      }

    });


  }


  //add  base and der unit in display table
  addUnitValues(derunitids, addunitlist) {

    this.apiService.createUnitList(addunitlist.value,this.tmpaddEanBars).subscribe((prod: Product) => {
      this.pageLoadingImg = false;


      if (prod.error != null) {
        this.result = prod.error;
      } else {
        this.result = '';
        //  add der unit   
        if (derunitids && derunitids.unit_name != null) {
          var featv = $("#duvlue").val();
          var fdeurate = $("#durate").val();
          var dean = $("#dunit_ean").val();
          if (dean) {
            dean = dean.toString();
            $("#dunit_ean").val('');
          }
          else {
            dean = 'no';
          }

          // check duplicates
          var res = this.coreService.checkDuplicateValue(this.prd_unit_ids, derunitids.unit_id);
          var resEan = this.coreService.checkDuplicateValueEan(this.prd_unit_ids, dean);
          if (dean == 'no') {
            resEan == 'undefined';
            dean = "";
          }

          console.log(res);
          console.log(resEan);

          if (typeof res == 'undefined' && typeof resEan == 'undefined') {
            this.prd_unit_ids.push({ "unit_type": 0, "unit_id": derunitids.unit_id, "unit_name": derunitids.unit_name, "ean_barcode": dean, "unit_rate": fdeurate.toString(), addBarcodes: JSON.parse(JSON.stringify(this.tmpaddEanBars)) });

            this.tmpaddEanBars = [{
              brc_id: 0,
              brc_ean_barcode: '',
              brc_unit_id : 0,
              loadingImg: false
            }];
            //  Remove duplicates     
            this.prd_unit_ids = this.coreService.removeDumplicateValueunit(this.prd_unit_ids);
            $("#duvlue").val('');
            $("#durate").val('');
            $('#dunit_valid').hide();
            this.derunitids = [];

          } else {
            $('#dunit_valid').show();
          }
        }
        //  end der units

      }
    });
    //  end addunitlist form
  }

  setEan(u_ean,index){
    this.prd_unit_ids[index].ean_barcode = u_ean;
  }
  setDefault(val,index){
    this.prd_unit_ids.forEach(function(item){ delete item.is_default });
    this.prd_unit_ids[index].is_default = val;
    this.def_unit = this.prd_unit_ids[index].unit_id;
  }
  deleteUnit(data: Unit) {
    if (data.unit_type == 1) {
      var res = confirm("Removing Base Unit will remove Derived units from the table");
      if (res) {
        this.prd_unit_ids = [];
        $('#dunit_valid').hide();
        $('#addbase-btn').show();
        $("#base-select").prop('disabled', false);
        $("#bunit_ean").prop('disabled', false);
        $("#buvlue").val('');
        $("#burate").val('');
        $('#inputder').hide();
        this.selDisable = false;
      }
    } else {
      var type = data.unit_id;
      var i;
      for (i = this.prd_unit_ids.length - 1; i >= 0; --i) {
        if (this.prd_unit_ids[i].unit_id == type) {
          this.prd_unit_ids.splice(i, 1);
        }
      }
    }

  }


  ProdUnits(prodid) {
    this.prd_unit_ids = [];
    let searchval = new FormData();
    searchval.append('prd_id', prodid);
    this.apiService.getProdUnits(searchval).subscribe((res) => {
      this.prd_unit_ids = res['data'];
    });
  }

  //form add Stock
  productStkSubmit(formgroup: { value: any; }) {
    var upt_unit_rates = [];
    var unit_rates = [];
    var errorobj = false;
    var errorbrch = false;
    
    var errordp = this.errValidDp(this.resDup = null);
    // add unit ids  
    $('input[name="unitratechk"]:checked').each(function () {

      upt_unit_rates.push({ "unit_id": $(this).val() });
    });
    //  add unit rates for final sotck submit arr:  unit_rates
    $('input.edit_unit_rate').each(function () {
      var attr_id = this.getAttribute("id");
      var unit_rt = $(this).val();
      $.each(upt_unit_rates, function (index, value) {

        if (value.unit_id == attr_id) {
          //  unit validation
          if (Number(unit_rt) < 0 || !Number(unit_rt)) {
            if (Number(unit_rt) === 0) {
              errorobj = false;
            }
            else {
              errorobj = true;
            }
            errorobj = true;
          }

          unit_rates.push({ "unit_id": attr_id, 'unit_rate': unit_rt });
        }
      });

    });
    this.mrp = (unit_rates[0].unit_rate);
if(this.userType=='ADMIN'){
  if(formgroup.value.branch_ids.length==0){
    errorbrch= true;
  }else{
    errorbrch= false;
  }
  this.errValidbrch(errorbrch)
}
    formgroup.value.prd_id = this.prodid;
    formgroup.value.cmp_stock_id = this.cmp_stock_id;
    formgroup.value.unit_rates = unit_rates;
    
  this.userType
    // validation
    var errorobjunitrate = this.errValidSr(errorobj);
    var prval = $('#prrate').val();
    var errorobjprate = this.errValidPrt(prval);
    if (!errorobjprate && !errorobjunitrate &&!errorbrch) {
      this.setstockloading=true;
      this.apiService.createProductStock(formgroup.value).subscribe((res: Product) => {
        this.setstockloading=false;
        if (res.error != null) {
       this.backendError=res.error;
        }
        else {
          this.addStock = 1;
          this.backendError=null;

          this.coreService.createfunct(formgroup, res.error, 'Updated');
          $('#edit-set-stockModal').hide();
          // $('.modal-backdrop').hide();
          $('#stockmodal').click();

          this.unitsforstock = [];
          // this.setStock = 0;
         
          this.branchselids = [];
          this.StockExcludeBranches(this.prodid)
          if(this.userType =='ADMIN'){
            this.StockAlertbranches(this.prodid)
            }
          this.stockSet(this.prodid);
        }
      });
    }


  }



  // validation
  errValidSr(errorobj: boolean) {
    if (errorobj == true) {
      $('#err_sr').show();
      return true;
    }
    else {
      $('#err_sr').hide();
      errorobj = false;
      return false;
    }
  }
  errValidDp(errorobj: boolean) {
    if (errorobj == true) {
      $('#err_dp').show();
      return true;
    }
    else {
      $('#err_dp').hide();
      return false;

    }
  }

  errValidQty(errFied: number) {
    if (Number(errFied) <= 0 || isNaN(errFied)) {
      $('#err_opqty').show();
      return true;
    } else {
      $('#err_opqty').hide();
      return false;
    }

  }

  errValidPrt(errFied: any) {
    if (isNaN(errFied)) {
      $('#err_prate').show();
      return true;
    } else {
      $('#err_prate').hide();
      return false;
    }

  }
  /*
    Start
    Author: fayis
    Created: 09.10.2024
    Description:  set stock super admin validation
  */ 
  errValidAlertbrch(errorbrch: boolean) {

    if (errorbrch == true) {
      $('#err_br_alrt').show();
      return true;
    }
    else {
      $('#err_br_alrt').hide();
      return false;

    }

  }

  errValidbrch(errorbrch: boolean) {

    if (errorbrch == true) {
      $('#err_br').show();
      return true;
    }
    else {
      $('#err_br').hide();
      return false;

    }

  }

  stockSet(prdId) {
    let searchval = new FormData();
    searchval.append("prd_id", prdId);
    this.apiService.stockSet(searchval).subscribe((res) => {
      this.setStock = res['data']['stock'];
      this.cmp_stock_id = res['data']['cmp_stock_id'];
      // console.log(res['data']);
      this.prodLoader = true;

    });
  }

  file(file) {
    // console.log($('#logo_img').val());

    //this.template.header_img = file;
    this.prd_img = file.target.files[0];
    var render = new FileReader();

    var nn = render.readAsDataURL(file.target.files[0]);
    render.onload = (event: any) => {
      this.prdImgUrl = event.target.result;
    }

  }
  fileChangeEvent(fileInput: any) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 2048000;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 200;
      const max_width = 200;
      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
          'Maximum size allowed is ' + max_size / 1000 / 1024 + 'MB';

        return false;
      }

      if (allowed_types.indexOf(fileInput.target.files[0].type) !== -1) { } else {
        this.imageError = 'Only Images are allowed ( JPG | PNG )';
        return false;
      }



      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          // console.log(img_height, img_width);


          if (img_height > max_height || img_width > max_width) {
            this.imageError =
              'Maximum dimentions allowed ' +
              max_height +
              ' X ' +
              max_width +
              'px';
            return false;
          } else {
            this.prdImgUrl = e.target.result;
          }
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);




    }
  }

  showCalculateMdl(id: number) {
    $("#sp_inc_tax").val('');
    $("#sp").val('');
    $("#mrp_id").val(id);
    $("#calculateMdl").show();
  }
  closeCalculateMdl(){
    $("#sp_inc_tax").val('');
    $("#sp").val('');
    $("#sp_tax").val('');
    $("#calculateMdl").hide();
  }
  calculateTax(keyword){
    if(!isNaN(keyword)){
      let t = keyword- ((keyword * 100) / (this.selectedData.prd_tax + 100));
      let p = keyword-t;
      $("#sp").val(p);
      $("#sp_tax").val(t);
    }else{
      $("#sp").val('');
      $("#sp_tax").val('');
    }
  }
  showCalculatedTax(){
    let mrp_id = $("#mrp_id").val();
    let sp = $("#sp").val();
    $(".stk_mrp_"+mrp_id).val(sp);
    $("#calculateMdl").hide();
  }
  translate(){
    if(this.selectedData.prd_name){
      let searchval = new FormData();
      searchval.append("prd_name", this.selectedData.prd_name);
      this.apiService.translateProductName(searchval).subscribe((res) => {
        this.selectedData.prd_alias = res['data'];
      });
    }
  }
  createDUnit(form: { value: any; }) {
    this.apiService.createUnit(form.value).subscribe((unit: Unit) => {
      if (unit.error != null) {
        this.result2 = unit.error;
      }
      else {
        this.result2 = '';
        this.coreService.showMessage(unit.message);
        this.der_unit_name = '';
        this.der_unit_base_qty= '';
        this.der_unit_code= '';
        this.der_unit_display= '';
        this.der_unit_remarks= '';
        this.apiService.readDerbyId(this.der_base_unit).subscribe((res: Unit[]) => {
          this.derunits = res['data'];
        });
        document.getElementById('closeAddMdl').click();
      }

    });
  }
  searchDUnit(search: string, flag) {
    let searchval = new FormData();
    this.der_unit_code= search;
    this.der_unit_display= search;
    searchval.append("unit_name", search);
    this.apiService.getUnitByName(searchval).subscribe((res) => {
      this.unit_all = res['data'];

    });
    if (flag == 'y')
      $('.prev-list-unit').show();
  }
  removeName() {
    this.unit_all = [];
    $('.prev-list').hide();
  }
  removeUnitsug() {
    $('.prev-list-unit').hide();
  }

  
  addMore() {

    this.addEanBars.push({
      brc_id: 0,
      brc_ean_barcode: '',
      brc_unit_id : 0
    });
  }
  remove(index){
    this.addEanBars.splice(index, 1);
  }

  addMoretmp() {

    this.tmpaddEanBars.push({
      brc_id: 0,
      brc_ean_barcode: '',
      brc_unit_id : 0,
      loadingImg: false
    });
  }
  removetmp(index){
    this.tmpaddEanBars.splice(index, 1);
  }
  removeAdditNewAdded(index){
    this.prd_unit_ids[this.drvdAddBarcodCurntIndx].addBarcodes.splice(index, 1);
  }

  deleteAddBarcode(index){
      if (confirm("Delete Permanently ?")) {
        this.extEanBars[index].loadingImg = true;
        this.apiService.deleteAddBarcode({brc_id: this.extEanBars[index].brc_id}).subscribe((prod: Product) => {
          this.extEanBars[index].loadingImg = false;
          this.extEanBars.splice(index, 1);
          this.ProdUnits(this.prodid);
        });

      }
  }

  deleteAddBarcodeDerived(data){
      if (confirm("Delete Permanently ?")) {
        data.loadingImg = true;
        this.apiService.deleteAddBarcode({brc_id: data.brc_id}).subscribe((prod: Product) => {
          data.loadingImg = false;
          // this.ProdUnits(this.prodid);
          // this.extDerivedEanBars.splice(this.extDerivedEanBars.findIndex(a => a.brc_id === data.brc_id) , 1)
          // this.extDerivedEanBars.splice(this.extDerivedEanBars.findIndex(a => a.brc_id === data.brc_id) , 1)
          var tmpIndx = this.prd_unit_ids.findIndex(a => a.produnit_id === this.drvdAddBarcodCurntUnitId);
          this.prd_unit_ids[tmpIndx].add_barcode.splice(this.prd_unit_ids[tmpIndx].add_barcode.findIndex(a => a.brc_id === data.brc_id) , 1)
        });
      }
  }

  updateAddBarcodeDerived(produnit_id){

    // add_barcode
    // console.log(data);
    var tmpIndx = this.prd_unit_ids.findIndex(a => a.produnit_id === produnit_id);
    if(! this.prd_unit_ids[tmpIndx].hasOwnProperty('addBarcodes')){
      console.log('in if');
      this.prd_unit_ids[tmpIndx].addBarcodes = [{
          brc_id: 0,
          brc_ean_barcode: '',
          brc_unit_id : 0,
        }];
    }
    this.drvdAddBarcodCurntUnitId = produnit_id
    this.extDerivedEanBars = this.prd_unit_ids[tmpIndx].add_barcode;
    this.drvdAddBarcodCurntIndx = tmpIndx;
    // if(! this.prd_unit_ids[this.drvdAddBarcodCurntUnitId].hasOwnProperty('addBarcodes')){
    //   console.log('in if');
    //   this.prd_unit_ids[this.drvdAddBarcodCurntUnitId].addBarcodes = [{
    //       brc_id: 0,
    //       brc_ean_barcode: '',
    //       brc_unit_id : 0,
    //     }];
    // }
    // console.log(this.prd_unit_ids);
  }
  addDerivedExistingMore(){
    this.prd_unit_ids[this.drvdAddBarcodCurntIndx].addBarcodes.push({
      brc_id: 0,
      brc_ean_barcode: '',
      brc_unit_id : 0,
    });
  }
/*
    Start
    Author: fayis
    Created: 09.10.2024
    Description: stock alert creation  and set stock super admin working properly
  */ 


  StockExcludeBranches(prd_id){
    let searchval = new FormData();
    searchval.append("prd_id", prd_id);
    this.apiService.getExcludeBranchesforStock(searchval).subscribe((res: Branch) => {
      this.exclude_branches = res['branches'];
      this.display_stock_alert = res['displayStockAlert'];
    });
  }
  getstockalert(data){
    let searchval = new FormData();
    searchval.append("prd_id", data);
    this.apiService.getBranchstockAlert(searchval).subscribe((res) => {
      if(this.userType =='ADMIN'){
        this.branch_Stk_alrt = res['data']
      }else{
      this.branch_Stk_alrt = res['data'][0];
      this.branch_prd_max_stock= res['data'][0].bs_max_stock;
      this.branch_prd_min_stock= res['data'][0].bs_min_stock;
      this.branch_prd_maxstock_alert= res['data'][0].bs_maxstock_alert;
      this.branch_prd_minstock_alert= res['data'][0].bs_minstock_alert;
    }
        
   
    });
  }
  branchWiseStockAlert(branchId){
if(branchId && this.branch_Stk_alrt){
  this.branch_stock = this.branch_Stk_alrt.filter(x => x.bs_branch_id == branchId);
  if(this.branch_stock){
    this.branch_prd_max_stock= this.branch_stock[0].bs_max_stock;
    this.branch_prd_min_stock=  this.branch_stock[0].bs_min_stock;
    this.branch_prd_maxstock_alert= this.branch_stock[0].bs_maxstock_alert;
    this.branch_prd_minstock_alert=  this.branch_stock[0].bs_minstock_alert;
  }

  

}
  }
  productStkAlrtSubmit(formgroup: { value: any; }){
    var errorAlertbrch = false;
    if(this.userType=='ADMIN'){
      if(formgroup.value.branchsId == null){
        errorAlertbrch= true;
      }else{
        errorAlertbrch= false;
        console.log('helooooooo');
        console.log(errorAlertbrch);
        
      }
      this.errValidAlertbrch(errorAlertbrch)
    }
    
if(!errorAlertbrch){

  formgroup.value.prd_id = this.prodid;
  // formgroup.value.cmp_stock_id = this.cmp_stock_id;

  this.apiService.setStockAlertBranchWise(formgroup.value).subscribe((res: Product) => {    

    if(res['error']){
      this.stock_error=res['error']
    }else{
      this.stock_error=null;
      this.coreService.showMessage(res.message);
      if(this.userType =='ADMIN'){
        this.StockAlertbranches(this.prodid)
        }
    }
  });

}
   

  }

  StockAlertbranches(prd_id){
    let searchval = new FormData();
    searchval.append("prd_id", prd_id);
    this.apiService.getAllBranchesforStockAlert(searchval).subscribe((res: Branch) => {
      this.StockAlert_branches = res['data'];
    });
  }
  productStkAlrtClose(){
    this.stock_error=null;
    this.branch_prd_max_stock= null;
    this.branch_prd_min_stock= null;
    this.branch_prd_maxstock_alert=null;
    this.branch_prd_minstock_alert=null;
    if(this.userType =='ADMIN'){
      this.branchsId=null
    }
  }

  /*
    End
    Author: fayis
    Created: 09.10.2024
    Description: stock alert and set stock super admin 
  */ 


}
